export const colors = {
  red: "#E61C3E",
  red2: "#C40B1A",
  white: "white",
  darkGrey: "#999",
  blue: "#0063c4",
  blue2: "#0002ad",
  green: "#00C276",
  green2: "#00B165",
  warning: "#FE7F2D",
  warning2: "#FE7F2D",

  black: "black",
  mediumGrey: "#c7c7c7",
  lightGrey: "#f2f2f2",
  boxShadow1: "box-shadow: 0 2px 8px 2px rgba(150,150,150,0.3);",
  boxShadowButtonBlue: "box-shadow: 2px 4px 8px 0px rgba(100,150,200,0.5);",
  boxShadowButtonRed: "box-shadow: 2px 4px 8px 0px rgba(150,100,100,0.5);",
  boxShadowButtonGreen: "box-shadow: 2px 4px 8px 0px rgba(100,150,100,0.5);",
  // boxShadowContentBox: "box-shadow: 2px 4px 8px 4px rgba(100, 120, 150, 0.2);",
  boxShadowContentBox:
    "box-shadow:  10px 10px 20px #cccccc, -10px -10px 20px #ffffff;",
  boxShadowInput: "box-shadow: 2px 4px 4px 1px rgba(0, 0, 0, 0.04);",
}

export const siteVars = {
  pageWidth: "1200px",
  buttonReset:
    "color: inherit; background: inherit; border-radius: 0; border: none; &:focus{outline: none}  padding: 0; font-size: 1rem; cursor: pointer; ",
  buttonNeutral: "&:hover{color: #fff; background: #000; } ",
  buttonWarning: `background: ${colors.red}; color: #fff; &:hover{ color: ${colors.red}; background: #fff; } `,
  buttonGreen: `background: ${colors.green}; color: #fff; &:hover{ color: ${colors.green}; background:#fff; } `,
}

export const db = {
  // lambda: "http://localhost:9000/.netlify/functions/server/",
  lambda: "https://teamgym.netlify.app/.netlify/functions/server/",
}

export const categories = {
  top: [
    "Tumbling",
    "Trampett",
    "Floor",
    "Strength and conditioning",
    "Flexibility",
    "Other",
  ],
  middle: [
    {
      value: "Basics",
      parent: "Tumbling",
    },
    {
      value: "Backwards",
      parent: "Tumbling",
    },
    {
      value: "Forward",
      parent: "Tumbling",
    },
    {
      value: "Basics",
      parent: "Trampett",
    },
    {
      value: "Free rotations",
      parent: "Trampett",
    },
    {
      value: "Pegasus",
      parent: "Trampett",
    },
    {
      value: "Choreography",
      parent: "Floor",
    },
    {
      value: "Difficulty elements",
      parent: "Floor",
    },
    {
      value: "Upper body",
      parent: "Strength and conditioning",
    },
    {
      value: "Core",
      parent: "Strength and conditioning",
    },
    {
      value: "Lower body",
      parent: "Strength and conditioning",
    },
    {
      value: "Upper body",
      parent: "Flexibility",
    },
    {
      value: "Core",
      parent: "Flexibility",
    },
    {
      value: "Lower body",
      parent: "Flexibility",
    },

    //OTHERS
    {
      value: "Other",
      parent: "Other",
    },
    {
      value: "Other",
      parent: "Tumbling",
    },
    {
      value: "Other",
      parent: "Trampett",
    },
    {
      value: "Other",
      parent: "Floor",
    },
    {
      value: "Other",
      parent: "Strength and conditioning",
    },
    {
      value: "Other",
      parent: "Flexibility",
    },
  ],
  bottom: [
    {
      value: "Handstand",
      parent: "Basics",
      topParent: "Tumbling",
    },
    {
      value: "Backroll",
      parent: "Basics",
      topParent: "Tumbling",
    },
    {
      value: "Forwardroll",
      parent: "Basics",
      topParent: "Tumbling",
    },
    {
      value: "Round off",
      parent: "Backwards",
      topParent: "Tumbling",
    },
    {
      value: "Back handspring",
      parent: "Backwards",
      topParent: "Tumbling",
    },
    {
      value: "Salto",
      parent: "Backwards",
      topParent: "Tumbling",
    },
    {
      value: "Double salto",
      parent: "Backwards",
      topParent: "Tumbling",
    },
    {
      value: "Tripple salto",
      parent: "Backwards",
      topParent: "Tumbling",
    },
    {
      value: "Handspring",
      parent: "Forward",
      topParent: "Tumbling",
    },
    {
      value: "Start salto",
      parent: "Forward",
      topParent: "Tumbling",
    },
    {
      value: "End salto",
      parent: "Forward",
      topParent: "Tumbling",
    },
    {
      value: "Run up",
      parent: "Basics",
      topParent: "Trampett",
    },
    {
      value: "Strike",
      parent: "Basics",
      topParent: "Trampett",
    },
    {
      value: "Landing",
      parent: "Basics",
      topParent: "Trampett",
    },
    {
      value: "Twisting",
      parent: "Free rotations",
      topParent: "Trampett",
    },
    {
      value: "Single salto",
      parent: "Free rotations",
      topParent: "Trampett",
    },
    {
      value: "Double salto",
      parent: "Free rotations",
      topParent: "Trampett",
    },
    {
      value: "Tripple salto",
      parent: "Free rotations",
      topParent: "Trampett",
    },
    {
      value: "Quadruple salto",
      parent: "Free rotations",
      topParent: "Trampett",
    },
    {
      value: "Handspring",
      parent: "Pegasus",
      topParent: "Trampett",
    },
    {
      value: "Handspring salto",
      parent: "Pegasus",
      topParent: "Trampett",
    },
    {
      value: "Handspring double salto",
      parent: "Pegasus",
      topParent: "Trampett",
    },
    {
      value: "Round off",
      parent: "Pegasus",
      topParent: "Trampett",
    },
    {
      value: "Tzukahara",
      parent: "Pegasus",
      topParent: "Trampett",
    },
    {
      value: "Tzukahara/ Kazamatsu double salto",
      parent: "Pegasus",
      topParent: "Trampett",
    },
    {
      value: "Swings",
      parent: "Choreography",
      topParent: "Floor",
    },
    {
      value: "Rythmic sequence",
      parent: "Choreography",
      topParent: "Floor",
    },
    {
      value: "Pirouettes",
      parent: "Difficulty elements",
      topParent: "Floor",
    },
    {
      value: "Power elements",
      parent: "Difficulty elements",
      topParent: "Floor",
    },
    {
      value: "Standing balances",
      parent: "Difficulty elements",
      topParent: "Floor",
    },
    {
      value: "Jumps/ Hops",
      parent: "Difficulty elements",
      topParent: "Floor",
    },
    {
      value: "Hand balances",
      parent: "Difficulty elements",
      topParent: "Floor",
    },
    {
      value: "Leaps",
      parent: "Difficulty elements",
      topParent: "Floor",
    },
    {
      value: "Acrobatic elements",
      parent: "Difficulty elements",
      topParent: "Floor",
    },
    {
      value: "Group elements",
      parent: "Difficulty elements",
      topParent: "Floor",
    },
    {
      value: "Shoulders",
      parent: "Upper body",
      topParent: "Strength and conditioning",
    },
    {
      value: "Arms",
      parent: "Upper body",
      topParent: "Strength and conditioning",
    },
    {
      value: "Chest",
      parent: "Upper body",
      topParent: "Strength and conditioning",
    },
    {
      value: "Upper back",
      parent: "Upper body",
      topParent: "Strength and conditioning",
    },
    {
      value: "Transverse",
      parent: "Core",
      topParent: "Strength and conditioning",
    },
    {
      value: "Frontal",
      parent: "Core",
      topParent: "Strength and conditioning",
    },
    {
      value: "Sagital",
      parent: "Core",
      topParent: "Strength and conditioning",
    },
    {
      value: "Lower back",
      parent: "Core",
      topParent: "Strength and conditioning",
    },
    {
      value: "Seat",
      parent: "Lower body",
      topParent: "Strength and conditioning",
    },
    {
      value: "Front leg",
      parent: "Lower body",
      topParent: "Strength and conditioning",
    },
    {
      value: "Backside leg",
      parent: "Lower body",
      topParent: "Strength and conditioning",
    },
    {
      value: "Calfs",
      parent: "Lower body",
      topParent: "Strength and conditioning",
    },
    {
      value: "Feet",
      parent: "Lower body",
      topParent: "Strength and conditioning",
    },
    {
      value: "Shoulders",
      parent: "Upper body",
      topParent: "Flexibility",
    },
    {
      value: "Arms",
      parent: "Upper body",
      topParent: "Flexibility",
    },
    {
      value: "Chest",
      parent: "Upper body",
      topParent: "Flexibility",
    },
    {
      value: "Upper back",
      parent: "Upper body",
      topParent: "Flexibility",
    },
    {
      value: "Transverse",
      parent: "Core",
      topParent: "Flexibility",
    },
    {
      value: "Frontal",
      parent: "Core",
      topParent: "Flexibility",
    },
    {
      value: "Sagital",
      parent: "Core",
      topParent: "Flexibility",
    },
    {
      value: "Lower back",
      parent: "Core",
      topParent: "Flexibility",
    },
    {
      value: "Seat",
      parent: "Lower body",
      topParent: "Flexibility",
    },
    {
      value: "Front leg",
      parent: "Lower body",
      topParent: "Flexibility",
    },
    {
      value: "Backside leg",
      parent: "Lower body",
      topParent: "Flexibility",
    },
    {
      value: "Calfs",
      parent: "Lower body",
      topParent: "Flexibility",
    },
    {
      value: "Feet",
      parent: "Lower body",
      topParent: "Flexibility",
    },

    //Others
    {
      value: "Other",
      parent: "Other",
      topParent: "Other",
    },
    {
      value: "Other",
      parent: "Other",
      topParent: "Trampett",
    },
    {
      value: "Other",
      parent: "Other",
      topParent: "Tumbling",
    },
    {
      value: "Other",
      parent: "Other",
      topParent: "Floor",
    },
    {
      value: "Other",
      parent: "Other",
      topParent: "Strength and conditioning",
    },
    {
      value: "Other",
      parent: "Other",
      topParent: "Flexibility",
    },
  ],
}
