import React from 'react'
import styled from "styled-components"

const StyledComingSoonScreen = styled.div`
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
display: flex;
align-items: center;
justify-content: center;
width: 100vw;
height: 100vh;
z-index: 1000;
background: black;
color: white;
/* pointer-events: none; */
img{
    pointer-events: none;
}
.cover-inner{
    width: 100%;
    max-width: 1200px;
    text-align: center;
    position: relative;
    h1{
    position: relative;
        top: -250px;
        font-weight: 400;
        font-size: 2rem;
        margin: 0;
        &.large{

        font-size: 3rem;
        }
    }

}
`

const ComingSoonScreen = () => {
    return (
        <StyledComingSoonScreen>
            <div className="cover-inner">
                <img src="/images/logo_index_nobg_dotSE.png" alt="logo" width="100%"/>
                <h1>Beta test starting soon
                    </h1>
                    {/* <h1 className="large">
                    2020-12-06
                    </h1> */}
            </div>
            
        </StyledComingSoonScreen>
    )
}

export default ComingSoonScreen
