import React from "react"
import styled from "styled-components"
import { siteVars, colors } from "./siteVars"
import Button from "./button"
import { Link } from "gatsby"

const StyledFooter = styled.div`
  background: ${colors.darkGrey};
  background: ${colors.white};

  padding: 3rem 0 8rem 0;

  .footer-inner {
    .logo-section {
      div {
        display: flex;
        align-items: center;
        img {
          width: 60px;
        }
        p {
          font-weight: 500;
          font-size: 1.6rem;
        }
      }
    }
    .footer-left {
    }
    padding: 0 1rem;
    max-width: ${siteVars.pageWidth};
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      justify-content: center;
      text-align: center;
      div {
        margin-top: 2rem;
      }
    }
    p {
      margin: 0;
      &.footer-strong-text {
        margin-bottom: 0.3rem;
        font-weight: 700;
        &.mtop {
          margin-top: 1rem;
        }
      }
    }
    h1 {
      margin: 0 0 1rem 0;
    }
  }

  button {
    &.sponsor-link-footer {
      margin-top: 1rem;
      /* margin-left: -1.5rem; */
      @media (max-width: 768px) {
        margin-left: 0;
      }
    }
  }
  @media (max-width: 768px) {
  }
`

const Footer = props => {
  return (
    <StyledFooter heroStart={props.heroStart}>
      <div className="footer-inner">
        <div className="logo-section">
          <div>
            <img src="/images/logo_blue.png" alt="teamgym.se" />
            <p>teamgym.se</p>
          </div>
        </div>
        <div className="footer-left">
          <p className="footer-strong-text">Created by</p>
          <p>Patrik Hellberg</p>
          <p>Niclas Butén</p>
          <br />
          <p className="footer-strong-text">Artwork by</p>
          <p>Lucas Bodin</p>
        </div>
        <div className="footer-middle">
          <p className="footer-strong-text">Sponsors</p>
          <p>Company AB</p>
          <p>Corporation Inc</p>
          <p>Sven Svensson</p>
          <a href="mailto:teamgym@katalysatorav.se?subject=Teamgym.se sponsor request">
            <Button color="blue" className="sponsor-link-footer">
              Become a sponsor
            </Button>
          </a>
          <Link to="/become-a-coach">
            <Button className="sponsor-link-footer">
              Get a coaches account
            </Button>
          </Link>
        </div>
        <div className="footer-right">
          <p className="footer-strong-text">Contact</p>
          <a href="mailto:teamgym@katalysatorav.se">teamgym@katalysatorav.se</a>
          <p className="footer-strong-text mtop">Community</p>
          <Button color="blue">Facebook</Button>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
