import React, { Component, useState, useContext } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { colors } from "./siteVars"
import { GlobalDispatchContext } from "../context/GlobalContextProvider"

const StyledProfileMin = styled.div`
  position: relative;
  display: flex;
  .minimized {
    transition: 200ms ease-in-out;
    border-radius: 50%;
    height: 40px;
    margin: auto;
    background: transparent;
    .profile-picture {
      width: 40px;
      object-fit: cover;
    }
    button {
      padding: 0;
      border: none;
      border-radius: 0;
      cursor: pointer;
      background: transparent;
      &:focus {
        outline: none;
      }
    }
    &:hover {
      background: ${colors.blue};
      .profile-picture {
        filter: invert(1);
      }
    }
  }
  .expanded {
    position: absolute;
    background: ${colors.white};
    top: 3.5rem;
    padding: 0;
    overflow: hidden;
    max-height: 0;
    transition: 200ms ease-in-out;
    ul {
      display: flex;
      flex-direction: column;
    }
    &.active {
      max-height: 300px;
    }
  }
  @media (max-width: 768px) {
    .minimized {
      margin-bottom: 1rem;
    }
  }
`

const ProfileMin = () => {
  const [open, setOpen] = useState(false)
  const dispatch = useContext(GlobalDispatchContext)

  // state = {
  //   open: false,
  // }

  // toggleOpen = () => {
  //   this.setState({
  //     open: !this.state.open,
  //   })
  // }

  return (
    <StyledProfileMin>
      <div className="minimized">
        {/* <button 
          onClick={this.toggleOpen}
          > */}
        <Link
          to="/account"
          onClick={() => {
            dispatch({ type: "SET_NAV_ITEM", item: "" })
          }}
        >
          <img
            src="/images/profile.png"
            alt="profile"
            className="profile-picture"
          />
        </Link>
        {/* </button> */}
      </div>
      <div className={`expanded ${open ? " active" : ""}`}>
        <ul>
          <Link to="/planner">
            <li>Account</li>
          </Link>
          <Link to="/planner">
            <li>Account</li>
          </Link>
        </ul>
      </div>
    </StyledProfileMin>
  )
}

export default ProfileMin
