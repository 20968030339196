import React, { useEffect, useContext } from "react"
import styled from "styled-components"

import "./style/index.css"
import AlertBox from "./alert-box"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import { siteVars } from "./siteVars"

import { navigate, useStaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import LargeAlertBox from "./large-alert-box"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../context/GlobalContextProvider"
import NavbarNew from "./navbar"
import Axios from "axios"
import { db } from "./siteVars"
import ComingSoonScreen from "./comingSoonScreen"

const StyledLayout = styled.div`
  ${props => (props.background ? "background: " + props.background + ";" : "")}
  .layout-background-image {
    height: 100vh;
    background-attachment: fixed;
  }
  .layout-inner {
    display: flex;
    flex-direction: column;
    max-width: ${siteVars.pageWidth};
    margin: auto;
    ${props => (props.heroStart ? "" : "min-height: 90vh;")}
  }
  .app-inner {
    flex-grow: 1;
    ${props => (props.heroStart ? "" : "padding: 3rem 1rem 1rem 1rem;")}
  }
  @media (max-width: 768px) {
    max-width: 100%;
  }
`

const Layout = props => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)

  // useEffect(() => {
  //   async function awaitUser() {
  //     const user = await getUser()
  //     if (user) {
  //       if (user.firstLogin) {
  //         navigate("/welcome")
  //       }
  //     }
  //   }
  //   awaitUser()
  // }, [])

  useEffect(() => {
    if (state.user.firstLogin) {
      navigate("/welcome")
    }
    // else if (typeof window !== `undefined`) {
    //   if (window.localStorage.getItem("authToken")) {
    //     Axios.get(
    //       db.lambda +
    //         "users/getSingle/" +
    //         window.localStorage.getItem("authToken")
    //     ).then(res => {
    //       if (res.data.user) {
    //         dispatch({
    //           type: "SIGN_IN",
    //           user: res.data.user,
    //           token: window.localStorage.getItem("authToken"),
    //         })
    //         navigate("/")
    //       }
    //     })
    //   }
    // }
  }, [])

  const image = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "teamgym-hero-images-medium.png" }) {
        relativePath
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap"
          rel="stylesheet"
        />
        <title>Teamgym.se</title>
      </Helmet>
      <AlertBox />
      <ComingSoonScreen />
      <LargeAlertBox />
      <NavbarNew />
      {props.preComponent ? props.preComponent() : null}
      <StyledLayout heroStart={props.heroStart}>
        {props.background ? (
          <BackgroundImage
            style={{
              backgroundAttachment: "fixed",
            }}
            className="layout-background-image"
            fluid={image.bg.childImageSharp.fluid}
          >
            <div className="layout-inner">
              <div className="app-inner">{props.children}</div>
            </div>
          </BackgroundImage>
        ) : (
          <div className="layout-inner">
            <div className="app-inner">{props.children}</div>
          </div>
        )}
      </StyledLayout>
      <Footer heroStart={props.heroStart} />
    </>
  )
}

export default Layout
