import React, { Component } from "react"
import styled from "styled-components"

const StyledLargeAlertBox = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  opacity: 0;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  pointer-events: none;
  transition: all 200ms ease-in-out;
  .large-alert-box-inner {
    padding: 4rem 10rem 2rem 10rem;
    background: linear-gradient(90deg, #ffffff, #cccccc);
    border-radius: 0.5rem;
    text-align: center;
    p {
      margin: 0;
      margin-bottom: 2rem;
    }
  }
  &.active {
    pointer-events: initial;
    opacity: 1;
  }
`

export default class LargeAlertBox extends Component {
  render() {
    return (
      <StyledLargeAlertBox
        id="largeAlertBox"
        className={this.props.active ? "active" : ""}
      >
        <div className="large-alert-box-inner">{this.props.content}</div>
      </StyledLargeAlertBox>
    )
  }
}
