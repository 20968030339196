import React, { Component, useContext } from "react"
import styled from "styled-components"
import { getUser } from "./handle-auth"
import Button from "./button"
import { Link } from "gatsby"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../context/GlobalContextProvider"

const StyledRegisterButton = styled.div``

const RegisterButton = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const { permissions } = state.user
  let content = null
  switch (permissions) {
    case undefined:
      content = (
        <Link
          to="/register"
          onClick={() => {
            dispatch({ type: "SET_NAV_ITEM", item: "" })
          }}
        >
          <Button color="green">Register</Button>
        </Link>
      )
      break
    case "user":
      content = (
        <Link
          to="/pro"
          onClick={() => {
            dispatch({ type: "SET_NAV_ITEM", item: "" })
          }}
        >
          <Button color="green">Get pro</Button>
        </Link>
      )
      break
    case "proUser":
      content = (
        <Link
          to="/become-a-coach"
          onClick={() => {
            dispatch({ type: "SET_NAV_ITEM", item: "" })
          }}
        >
          <Button color="green">Get a coaches account</Button>
        </Link>
      )
      break
    default:
      break
  }
  return <StyledRegisterButton>{content}</StyledRegisterButton>
}

export default RegisterButton
