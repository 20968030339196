import React from "react"
import styled from "styled-components"
import { colors } from "./siteVars"

const StyledAlertBox = styled.div`
  position: fixed;
  top: -3rem;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  opacity: 0;
  justify-content: center;
  z-index: 1000;
  transition: all 200ms ease-in-out;
  .alert-box-inner {
    padding: 1rem 5rem;
    background: linear-gradient(90deg, #1866ff, #4488ff);
    color: ${colors.white};
    border-radius: 0.5rem;
    text-align: center;
    p {
      margin: 0;
    }
  }
  &.active {
    opacity: 1;
    top: 6rem;
  }
`

const AlertBox = () => {
  return (
    <StyledAlertBox id="alertBox">
      <div className="alert-box-inner">
        <p id="alertBoxContent">Alert</p>
      </div>
    </StyledAlertBox>
  )
}

export default AlertBox
