import React from "react"
import styled from "styled-components"
import { colors } from "./siteVars"

const StyledButton = styled.button`
  border: none;
  border-radius: ${props => (props.circle ? "50%" : "5px")};
  font-size: 1rem;
  padding: ${props => (props.circle ? "0.8rem" : "0.8rem 1.8rem")};
  cursor: pointer;
  ${props =>
    props.color
      ? props.color
      : `
  color: ${colors.white};
  background: linear-gradient(
    -60deg,
    ${colors.blue},
    ${colors.blue2},
    ${colors.blue},
    ${colors.blue2},
    ${colors.blue}
  );
  ${colors.boxShadowButtonBlue}
  `}
  background-position: 30% 50%;
  background-size: 400% 100%;
  transition: 200ms ease-in-out;
  &:focus {
    outline: none;
    transform: scale(1);
  }
  &:hover {
    transform: scale(1.03);
    background-position: 100% 50%;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    ${props => (props.invert ? `filter: invert(1);` : "")};
  }
`
const Button = props => {
  let color = ""
  if (props.color) {
    switch (props.color) {
      case "red":
        color = `
                color: ${colors.white};
                background: linear-gradient(
                    -60deg,
                    ${colors.red},
                    ${colors.red2},
                    ${colors.red},
                    ${colors.red2},
                    ${colors.red}
                    );
                    ${colors.boxShadowButtonRed}
      `

        break
      case "green":
        color = `
                color: ${colors.white};
                background: linear-gradient(
                    -60deg,
                    ${colors.green},
                    ${colors.green2},
                    ${colors.green},
                    ${colors.green2},
                    ${colors.green}
                    );
                    ${colors.boxShadowButtonGreen}
      `

        break
      case "warning":
        color = `
                color: ${colors.white};
                background: linear-gradient(
                    -60deg,
                    ${colors.warning},
                    ${colors.warning2},
                    ${colors.warning},
                    ${colors.warning2},
                    ${colors.warning}
                    );
                    ${colors.boxShadowButtonRed}
      `

        break

      default:
        break
    }
  }
  return (
    <StyledButton
      onClick={props.onClick}
      className={props.className}
      color={color}
      type={props.type}
      invert={props.invert}
      circle={props.circle}
      style={props.style}
      disabled={props.disabled}
    >
      <span>{props.children}</span>
    </StyledButton>
  )
}

export default Button
