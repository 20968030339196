import React, { useContext, useState } from "react"
import styled from "styled-components"
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../context/GlobalContextProvider"

import { Link, graphql, useStaticQuery, navigate } from "gatsby"
import Img from "gatsby-image"
import { colors } from "./siteVars"
import RegisterButton from "./register-button"
import Button from "./button"
import ProfileMin from "./profile-min"
import Hamburger from "./hamburger"

//STYLE
const StyledNavBar = styled.nav`
  .navbar-top {
    ${colors.boxShadow1}
    background: ${colors.white};
    padding: 0.8rem;
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .navbar-logo {
      display: flex;
      align-items: center;
      &-image-container {
        width: 45px;
      }
      &-text-container {
        .logo-text {
          &-upper {
          transition: 200ms ease-in-out;
            margin: 0 0 0 0.5rem;
            font-weight: 500;
            font-size: 1.6rem;
          }
          &-lower {
            margin: 0 0 0 0.5rem;
            font-size: 0.9rem;
          }
        }
      }
      .navbar-logo-image-container{
        transition: 200ms ease-in-out;
      }
      &:hover{
        .logo-text-upper{
          transform: translateX(10px);
        }
        .navbar-logo-image-container{
          transform: rotateZ(-30deg);
        }
      }
    }
    .navbar-right-desktop {
      display: flex;
      align-items: center;
      ul {
        display: flex;
        margin-right: 0.5rem;
        li {
          padding: 0 0.3rem;
          transition: 200ms ease-in-out;
          &:hover {
            color: ${colors.blue};
          }
          &.active {
            color: ${colors.blue};
            font-weight: 500;
          }
        }
      }
      .navbar-divider {
        position: relative;
        &:after {
          position: absolute;
          top: calc(-25px / 2);
          width: 1px;
          height: 25px;
          background: ${colors.mediumGrey};
          content: "";
        }
      }
    }
    .navbar-buttons-section {
      margin-left: 0.8rem;
      display: flex;
      align-items: center;
      button {
        margin-left: 0.5rem;
      }
    }
  }
  .navbar-dropdown-mobile{
      display: none;
  }
  .navbar-right-mobile{
      display: none;
  }
  @media(max-width: 768px){
        .logo-text{
            &-upper{
                font-size: 1.3rem !important;
            }
        }
      .navbar-right-desktop{
          display: none !important;
      }
      .navbar-right-mobile{
          display: block;
          margin-right: 0.5rem;
      }
  .navbar-dropdown-mobile{
      ${colors.boxShadow1}
      background: ${colors.white};
      display: flex;
      align-items: center;
      flex-direction: column;
      position: fixed;
      top: -100vh;
      left: 0;
      right: 0;
      z-index: 99;
        padding-bottom: 2rem;
        transition: 200ms ease-in-out;
        &.active{
            top: 0;
        }
        ul{
            margin-bottom: 1rem;
            text-align: center;
            li{
                padding: 0.5rem 0;
                &.active {
            color: ${colors.blue};
            font-weight: 500;
          }
            }
        }
    &-inner{
        margin-top: 90px;
        .navbar-divider{
        position: relative;
            &::after{
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 1px;
                content:"";
                background: ${colors.mediumGrey};
            }
        }
        .navbar-buttons-section{
            padding-top: 1rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
          
            button{
                margin: 0.5rem auto;
                display: block;
                width: 100%;

            }
        }
    }
  }

  }
`

//COMPONENT START
const NavbarNew = () => {
  const state = useContext(GlobalStateContext)
  const dispatch = useContext(GlobalDispatchContext)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  let loc = []
  if (typeof window !== `undefined`) {
    loc = window.location.href.split("/")
  }

  //FUNCTIONS

  //Get logo image
  const logo = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo_blue_100px.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `).logo.childImageSharp.fluid

  //Inital links
  let links = [
    {
      to: "/planner",
      txt: "Teamgym planner",
    },
    {
      to: "/calculator",
      txt: "CoP calculator",
    },
  ]

  //Add links if url is planner
  if (loc[3] === "planner") {
    links.push(
      {
        to: "/planner/excercises",
        txt: "Excercises",
      },
      {
        to: "/planner/programs",
        txt: "Programs",
      }
    )
  }

  //Add admin links
  if (state.token && state.user.permissions === "admin") {
    links.push(links.push({ to: "/users", txt: "Users" }))
  }

  //Check location to select logo text
  let logoText = null
  switch (loc[3]) {
    case "planner":
      logoText = <p className="logo-text-lower">planner</p>
      break
    case "calculator":
      logoText = <p className="logo-text-lower">calculator</p>
    default:
      break
  }

  //Create ul
  const ul = (
    <ul>
      {links.map(link => (
        <Link
          to={link.to}
          key={link.txt}
          onClick={() => {
            dispatch({ type: "SET_NAV_ITEM", item: link.txt })
            setMobileMenuOpen(!mobileMenuOpen)
          }}
        >
          <li className={state.activeNavItem === link.txt ? "active" : ""}>
            {link.txt}
          </li>
        </Link>
      ))}
    </ul>
  )

  //Create buttons section
  const buttonSection = (
    <div className="navbar-buttons-section">
      {state.user.permissions ? <ProfileMin /> : null}
      {state.user.permissions ? (
        <>
          <RegisterButton />
          <Button
            onClick={() => {
              dispatch({ type: "SIGN_OUT" })
              if (typeof window !== `undefined`) {
                window.localStorage.removeItem("authToken")
              }
              setMobileMenuOpen(!mobileMenuOpen)
              dispatch({ type: "SET_NAV_ITEM", item: "" })
              navigate("/")
            }}
          >
            Sign out
          </Button>
        </>
      ) : (
        <>
          <RegisterButton />
          <Link
            to="/login"
            onClick={() => {
              dispatch({ type: "SET_NAV_ITEM", item: "" })
            }}
          >
            <Button>Sign in</Button>
          </Link>
        </>
      )}
    </div>
  )
  return (
    <>
      <div
        style={{
          height: "60px",
        }}
      ></div>
      <StyledNavBar>
        <div className="navbar-top">
          <Link
            to="/"
            onClick={() => {
              dispatch({ type: "SET_NAV_ITEM", item: "" })
            }}
          >
            <div className="navbar-logo">
              <div className="navbar-logo-image-container">
                <Img fluid={logo} alt="teamgym.se" />
              </div>
              <div className="navbar-logo-text-container">
                <p className="logo-text-upper">teamgym.se</p>
                {logoText}
              </div>
            </div>
          </Link>
          <div className="navbar-right-desktop">
            {ul}
            <div className="navbar-divider"></div>
            {buttonSection}
          </div>
          <div
            className="navbar-right-mobile"
            onClick={() => {
              setMobileMenuOpen(!mobileMenuOpen)
            }}
          >
            <Hamburger color={colors.mediumGrey} active={mobileMenuOpen} />
          </div>
        </div>
        <div
          className={`navbar-dropdown-mobile ${mobileMenuOpen ? "active" : ""}`}
        >
          <div className="navbar-dropdown-mobile-inner">
            {ul}
            <div className="navbar-divider"></div>
            {buttonSection}
          </div>
        </div>
      </StyledNavBar>
    </>
  )
}

export default NavbarNew
