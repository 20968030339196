import axios from "axios"
import { navigate } from "gatsby"
import { db } from "./siteVars"

//NEW FUNCTIONS

export const isWindow = () => {
  if (typeof window === "undefined") {
    global.window = {}
    return false
  } else return true
}

export const setToken = token => {
  if (isWindow) {
    window.localStorage.setItem("authToken", token)
  }
}

export const signedIn = () => {
  // if (isWindow) {
  if (typeof window !== `undefined`) {
    return window.localStorage.getItem("authToken") ? true : false
  }
}

export const signOut = () => {
  // if (isWindow) {
  if (typeof window !== `undefined`) {
    window.localStorage.removeItem("authToken")
    navigate("/")
  }
}

export const getUser = async () => {
  if (isWindow) {
    const token = window.localStorage.getItem("authToken")
    if (token) {
      const res = await axios.get(db.lambda + "users/getSingle/" + token)

      return res.data.user
    } else {
      const path = window.location.pathname.split("/")[1]
      const pathSecond = window.location.pathname.split("/")[2]
      if (path === "planner" && pathSecond !== "register") navigate("/login")
    }
  }
}
